import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'

interface CookieDeclarationProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Cookiedeclaration
}

const StyledSection = styled.div`
  & p,
  table th,
  table td {
    color: ${(props) => props?.theme?.color?.primary};
    font-family: ${(props) => props?.theme?.font?.family?.primary};
    font-size: ${(props) => props?.theme?.font?.size?.medium};
    line-height: 35px;
  }
`

const CookieDeclaration = ({ fields }: CookieDeclarationProps) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!ref.current) return () => {}

    const script = document.createElement('script')
    script.setAttribute('id', 'CookieDeclaration')
    script.setAttribute(
      'src',
      'https://consent.cookiebot.com/3832ca88-eef5-44ac-8e2a-f1c283b81f8d/cd.js'
    )
    script.setAttribute('type', 'text/javascript')
    script.setAttribute('async', 'true')
    ref.current?.append(script)

    return () => {}
  }, [])

  return (
    <StyledSection>
      <div className="container">
        <div className="row py-5">
          <div ref={ref} />
        </div>
      </div>
    </StyledSection>
  )
}

export default CookieDeclaration
